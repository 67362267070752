import(/* webpackMode: "eager" */ "/app/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@react-oauth/google/dist/index.esm.js");
import(/* webpackMode: "eager" */ "/app/node_modules/antd/es/config-provider/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/src/components/Page/AppConfig.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Page/Header/HeaderMenu.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Page/Header/HeaderMobileMenu.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Page/Header/LanguageSwitcher.tsx")